import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  private loadingCountsSubject = new BehaviorSubject<Map<string, number>>(
    new Map()
  );

  loadingCountsMap$ = this.loadingCountsSubject.asObservable();

  constructor() {}

  show(identifier: string = 'default', allowDuplicates: boolean = false) {
    const key = identifier;
    const currentCounts = this.loadingCountsSubject.value;
    const count = currentCounts.get(key) || 0;
    if (!allowDuplicates && count > 0) {
      return;
    }
    currentCounts.set(key, count + 1);
    this.loadingCountsSubject.next(new Map(currentCounts));
    this.updateLoadingState();
  }

  hide(identifier?: string) {
    const key = identifier || 'default';
    const currentCounts = this.loadingCountsSubject.value;
    const count = currentCounts.get(key) || 0;
    if (count > 1) {
      currentCounts.set(key, count - 1);
    } else {
      currentCounts.delete(key);
    }
    this.loadingCountsSubject.next(new Map(currentCounts));
    this.updateLoadingState();
  }

  flush() {
    this.loadingCountsSubject.next(new Map());
    this.loadingSubject.next(false);
  }

  private updateLoadingState() {
    const isLoading = this.loadingCountsSubject.value.size > 0;
    this.loadingSubject.next(isLoading);
  }
}
