import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromActions from './layout.actions';
import * as fromReducer from './layout.reducer';
import * as fromSelectors from './layout.selectors';

import { Layout, Theme, Nav } from '../../models';
import { LoadingService } from '../services/loading.service';

@Injectable({ providedIn: 'root' })
export class LayoutFacade {
  layout$: Observable<Layout> = this.store.pipe(
    select(fromSelectors.selectLayout)
  );

  theme$: Observable<Theme> = this.store.pipe(
    select(fromSelectors.selectSelectedTheme)
  );

  themes$: Observable<Array<Theme>> = this.store.pipe(
    select(fromSelectors.selectThemes)
  );

  isLoading$: Observable<boolean> = this.loadingService.loading$;

  loadingCountsMap$ = this.loadingService.loadingCountsMap$.pipe(
    map((countsMap) => {
      const countsObj: { [key: string]: number } = {};
      countsMap.forEach((value, key) => {
        countsObj[key] = value;
      });
      return countsObj;
    })
  );

  logoLoaded$: Observable<boolean> = this.store.pipe(
    select(fromSelectors.selectLogoLoaded)
  );

  feedback$: Observable<any> = this.store.pipe(
    select(fromSelectors.selectFeedback)
  );

  constructor(
    private store: Store<fromReducer.State>,
    private loadingService: LoadingService
  ) {}

  setLayout(layout: Layout) {
    this.store.dispatch(new fromActions.SetLayoutAction(layout));
  }

  setLoadingOn(identifier?: string) {
    this.loadingService.show(identifier);
  }

  setLoadingOff(identifier?: string) {
    this.loadingService.hide(identifier);
  }

  flushLoading() {
    this.loadingService.flush();
  }

  loadThemes() {
    this.store.dispatch(new fromActions.LoadThemesAction());
  }

  setLogoLoading(isLoading: boolean) {
    this.store.dispatch(new fromActions.SetLogoLoadedAction(isLoading));
  }

  openFeedback() {
    this.store.dispatch(new fromActions.OpenFeedbackAction());
  }

  setFeedback(feedback: string) {
    this.store.dispatch(new fromActions.SetFeedbackAction(feedback));
  }
}
